import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[libTrim]',
  standalone: true,
})
export class TrimDirective {
  constructor(private readonly control: NgControl) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    const trimmedValue = value.trim();
    this.control.control?.setValue(trimmedValue);
  }
}
