import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'datePipe',
  standalone: true,
})
export class DatePipePipe implements PipeTransform {
  transform(time: any, format?: string): string {
    const f = format || 'DD-MMM-yyyy';
    if (!time || !moment(time).isValid()) {
      return '';
    }
    return moment(time).format(f);
  }
}
