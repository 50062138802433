<areeb-input
  [id]="id()"
  [inputFormControlName]="inputFormControlName()"
  [type]="'text'"
  [name]="name()"
  [placeholder]="placeholder()"
  [label]="label()"
  [endIcon]="'./assets/images/icons/calendar.svg'"
  [readonly]="true"
  (endIconCLick)="!disabled() ? openCalendar() : null"
  [value]="(ViewValue() | datePipe : 'DD-MMM-yyyy') ?? undefined"></areeb-input>

<div class="at-position-relative">
  @if (showCalendar) {
  <div
    class="date-picker at-bg-white at-p-xl at-flex at-flex-column at-gap-xl at-border at-border-neutral-200 at-radius-md at-shadow-md at-position-absolute">
    <div class="header at-pb-xl at-border-bottom at-border-neutral-200 at-h6">
      <div class="at-flex at-justify-between at-align-center at-gap-sm">
        <div
          class="at-flex at-align-center at-gap-x-sm"
          [ngClass]="{
            'at-cursor-pointer': !disabled(),
            'at-cursor-not-allowed': disabled()
          }"
          (click)="showMonths()">
          {{ months[currentMonth].name }}
          <svg
            _ngcontent-ng-c3544122015=""
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none">
            <path
              _ngcontent-ng-c3544122015=""
              d="M15.4336 6.81689H10.2419H5.56695C4.76695 6.81689 4.36695 7.78356 4.93361 8.35023L9.25028 12.6669C9.94195 13.3586 11.0669 13.3586 11.7586 12.6669L13.4003 11.0252L16.0753 8.35023C16.6336 7.78356 16.2336 6.81689 15.4336 6.81689Z"
              fill="#4B5563"></path>
          </svg>
        </div>
        <div
          class="at-cursor-pointer at-flex at-align-center at-gap-x-sm"
          (click)="showYears()">
          {{ currentYear }}
          <svg
            _ngcontent-ng-c3544122015=""
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none">
            <path
              _ngcontent-ng-c3544122015=""
              d="M15.4336 6.81689H10.2419H5.56695C4.76695 6.81689 4.36695 7.78356 4.93361 8.35023L9.25028 12.6669C9.94195 13.3586 11.0669 13.3586 11.7586 12.6669L13.4003 11.0252L16.0753 8.35023C16.6336 7.78356 16.2336 6.81689 15.4336 6.81689Z"
              fill="#4B5563"></path>
          </svg>
        </div>
      </div>
      <!-- <div class="at-flex">
        <button
          (click)="prevMonth()"
          class="at-bg-transparent at-mx-md at-cursor-pointer">
          &#10094;
        </button>
        <button
          (click)="nextMonth()"
          class="at-bg-transparent at-mx-md at-cursor-pointer">
          &#10095;
        </button>
      </div> -->
    </div>
    <div class="body at-border-bottom at-border-neutral-200">
      @if (!isShowMonths() && !isShowYears()) {
      <div class="">
        <div class="days-of-week at-flex">
          @for (day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']; track
          $index) {
          <div>
            {{ day }}
          </div>
          }
        </div>
        <div class="dates at-row">
          @for (date of monthDates; track $index) {
          <div
            class="day at-flex at-align-center at-justify-center at-cursor-pointer at-p-x-sm at-text-center"
            [ngClass]="{
              'prev-month': date.getMonth() < currentMonth,
              'next-month': date.getMonth() > currentMonth
            }"
            (click)="selectDate(date)">
            <span
              class="month-day at-flex at-align-center at-justify-center"
              [ngClass]="{
                  'selected-day':
                  (date|date) === (selectedDate|date) || ((date | date) === (currentDate | date) && selectedDate === null),
                }">
              {{ date.getDate() }}
            </span>
          </div>
          }
        </div>
      </div>
      } @if (isShowMonths()) {
      <div class="at-row at-align-center at-justify-center at-cursor-pointer">
        @for (month of months; track $index) {
        <div
          class="month at-flex at-align-center at-justify-center at-p-md at-cursor-pointer"
          (click)="selectMonth(month)">
          <span
            class="at-flex at-align-center at-p-md"
            [ngClass]="{ 'selected-month': month.value === currentMonth }">
            {{ month.name }}
          </span>
        </div>
        }
      </div>
      } @if (isShowYears()) {
      <div
        class="at-row at-align-center at-justify-center at-cursor-pointer at-p-x-sm at-text-center">
        @for (year of reversedYears; track $index) {
        <div
          class="year at-flex at-align-center at-justify-center at-p-sm at-cursor-pointer"
          (click)="selectYear(year)">
          <span
            class="at-flex at-align-center at-justify-center at-p-md"
            [ngClass]="{ 'selected-year': year === currentYear }">
            {{ year }}
          </span>
        </div>
        }
      </div>
      }
    </div>
    <div class="footer at-flex at-justify-between at-gap-md">
      <button
        id="close"
        class="at-bg-transparent at-border at-border-neutral-300 at-text-neutral-700 at-fw-medium at-text-md at-py-sm at-px-lg at-radius-sm at-cursor-pointer at-w-100"
        (click)="close()">
        Close
      </button>
      <button
        id="apply"
        class="at-bg-primary-500 at-text-white at-fw-medium at-text-md at-py-sm at-px-lg at-border-primary-500 at-radius-sm at-cursor-pointer at-w-100"
        (click)="apply()"
        [disabled]="!selectedDate">
        Apply
      </button>
    </div>
  </div>
  }
</div>
