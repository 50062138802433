import { TruncatePipe } from './../../pipes/truncate/truncate.pipe';
import {
  AfterViewInit,
  Component,
  forwardRef,
  inject,
  input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ClickOutSideDirective } from '../../directives/clickOutSide.directive';
import { OptionItem } from '../option.interface';

@Component({
  selector: 'lib-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClickOutSideDirective,
    TruncatePipe,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class SelectComponent implements OnInit, AfterViewInit, OnDestroy {
  inputFormControlName = input.required<string>();
  id = input.required<string>();
  name = input.required<string>();

  label = input<string>();
  placeholder = input<string>('');
  inputClass = input<string>('');

  disabled = input<boolean>(false);
  readonly = input<boolean>();
  value = input<string | number>();
  required = input<boolean>(false);

  options = input<OptionItem[]>([]);
  selectedKeyValue = input<
    'id' | 'name' | 'alpha2code' | 'calling_code' | 'flag' | 'value' | 'label'
  >('id');
  selectedKeyName = input<
    'id' | 'name' | 'alpha2code' | 'calling_code' | 'flag' | 'label' | 'value'
  >('calling_code');

  hasSearch = input<boolean>(); // to add search option
  searchPlaceholder = input<string>('Search ...'); //to be able to translate or edit it
  noSearchResultLabel = input<string>('No search result'); //to be able to translate or edit it

  changeOption = output<OptionItem>();
  isDropdownOpen = false;
  selectedOption: OptionItem = <OptionItem>{};
  selectedValue?: string | number;
  focused?: boolean;
  notEmpty?: boolean;
  searchValue!: string;
  showNoSearchResult!: boolean;
  filteredOptions: OptionItem[] = [];

  private destroy$ = new Subject<void>();
  private parentContainer = inject(ControlContainer);

  private get parentFormGroup() {
    return this.parentContainer.control as FormGroup;
  }

  protected get childControl() {
    return this.parentFormGroup.controls[
      this.inputFormControlName()
    ] as FormControl;
  }

  ngOnInit(): void {
    if (this.disabled()) {
      this.childControl.disable();
    }
  }

  toggleDropdown() {
    this.filteredOptions = [];
    this.childControl.markAsTouched();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  ngAfterViewInit(): void {
    if (this.childControl) {
      setTimeout(() => {
        this.writeValue(this.childControl.value);
      }, 50);
      this.childControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          this.writeValue(value, false);
        });
    }
  }

  writeValue(value: string | number, updateFormControlValue?: boolean) {
    if (value && this.options()) {
      const match = this.options().find((item: OptionItem) => {
        return item[this.selectedKeyValue()] === value;
      });
      if (match) {
        this.selectOption(match, updateFormControlValue);
      }
    }
  }

  selectOption(item: OptionItem, updateFormControlValue?: boolean) {
    if (item) {
      this.selectedOption = item;
      if (updateFormControlValue) {
        this.childControl.patchValue(item[this.selectedKeyValue()]);
      }
      this.filteredOptions = [];
      this.selectedValue = item[this.selectedKeyName()];
      this.changeOption.emit(item);
      this.notEmpty = true;
    }
  }

  onDropdownSearch(event: Event) {
    if (event.target) {
      const searchValue = (<HTMLInputElement>event.target).value;
      if (this.options()) {
        if (searchValue) {
          this.filteredOptions = this.options().filter((item: OptionItem) => {
            return item?.name
              ? item?.name
                  .toLocaleLowerCase()
                  .includes(searchValue.toLocaleLowerCase())
              : '';
          });
          if (!this.filteredOptions.length) {
            this.showNoSearchResult = true;
          } else {
            this.showNoSearchResult = false;
          }
        } else {
          this.filteredOptions = [];
        }
      } else {
        this.showNoSearchResult = true;
      }
    }
  }

  clickedOutside() {
    this.isDropdownOpen = false;
    if (
      this.childControl.touched &&
      !this.childControl.value &&
      this.childControl.hasError('required')
    ) {
      this.childControl.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
