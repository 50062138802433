import { Component, input } from '@angular/core';

@Component({
  selector: 'lib-stat',
  standalone: true,
  imports: [],
  templateUrl: './stat.component.html',
  styleUrl: './stat.component.scss',
})
export class StatComponent {
  label = input.required<string>();
  overline = input<string>('');
  description = input<string>('');
  icon = input<string>('');
  tag = input<number>(0);
  width = input<string>();
}
