import { Component, computed, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToggleOption } from '../option.interface';

@Component({
  selector: 'lib-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  id = input.required<string>();
  formGroup = input.required<FormGroup>();
  inputFormControlName = input.required<string>();
  label = input<string>();
  disabled = input<boolean>(false);
  options = input<ToggleOption[]>([]);
  required = input<boolean>(false);
  values: string[] = [];
  singleCheckBox = input.required<boolean>();

  updateValue($event: any, value: string | boolean | number) {
    if ($event.target.checked) {
      this.values.push(value as string);
    } else {
      this.values = this.values.filter(v => v != value);
    }
    this.formGroup().controls[this.inputFormControlName()].setValue(
      this.values
    );
  }

  isOptionChecked(value: string | boolean | number): boolean {
    return this.values?.includes(value as string);
  }
}
