import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[libNumber]',
  standalone: true,
})
export class NumberDirective implements AfterViewInit {
  private inputElement!: HTMLInputElement;
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
  ];

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.inputElement = this.el.nativeElement.querySelector('input');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    // Check if the key is a digit (top row or numpad) or a dot
    const isTopRowDigit = event.keyCode >= 48 && event.keyCode <= 57;
    const isNumpadDigit = event.keyCode >= 96 && event.keyCode <= 105;
    const isDot = event.key === '.';

    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    const dotIndex = value.indexOf('.');
    if (
      (dotIndex !== -1 && value.length - dotIndex > 3) ||
      (isDot && value.includes('.'))
    ) {
      event.preventDefault();
      return;
    }

    if (!isTopRowDigit && !isNumpadDigit && !isDot) {
      event.preventDefault();
    }
  }
}
