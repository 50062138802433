import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  QueryList,
  forwardRef,
  input,
  output,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'lib-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class TabsComponent implements AfterViewInit {
  @ContentChildren(forwardRef(() => TabComponent), { descendants: true })
  tabs!: QueryList<TabComponent>;
  tabPill = input<boolean>(false);
  onSelectTab = output<number>();
  tabsBorder = input<boolean>(false);

  ngAfterViewInit() {
    this.selectTab(this.tabs.first, 0);
  }

  selectTab(tab: TabComponent, index: number) {
    this.tabs.toArray().forEach(tab => tab.active.set(false));
    tab.active.set(true);
    this.onSelectTab.emit(index);
  }
}
